import { Fragment } from 'react'
import { Translate } from './i18n'
import { openModal } from '../components/modal'

export default (value, custom = {}) => openModal(opts => {
  let message;
  if (typeof value === 'string') {
    message = (
      <p className="lead my-3 text-center">
        <Translate value={value} />
      </p>
    );
  } else {
    message = value;
  }

  return (
    <Fragment>
      <div className="modal-body">
        {message}
      </div>
      <div className="modal-footer d-flex">
        <button type="button" className="btn btn-default" onClick={opts.onCancel}>
          {opts.cancelText}
        </button>
        <button type="button" className="btn btn-primary ml-auto" onClick={opts.onSuccess}>
          {opts.okText}
        </button>
      </div>
    </Fragment>
  );
}, {
  size: 'sm',
  cancelText: <Translate value="form.cancel" />,
  okText: <Translate value="core.ok" />,
  ...custom
});
