const LIMIT = 80;

const truncate = text => {
  if(!text) return '';
  text = '' + text;

  return (text.length <= LIMIT) ? text : `${text.substr(0, LIMIT)}...`;
}

export const ng = app => {
  app.filter('truncate', () => truncate);

  app.directive('ngTruncate', () => ({
    link(scope, element, attrs) {
      var text = scope.$eval(attrs.ngTruncate);
      element.text(truncate(text));
    }
  }));
}

export default truncate;
