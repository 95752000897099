let defaults = { delimiter: ', ', expression: '$resource.name', limit: null };

const build = (scope, list, options) => {
  let limit = options.limit ? Math.min(options.limit, list.length) : list.length;
  if(!list) list = [];
  let i = 0, result = "";
  while(i < limit) {
    if(i > 0) result += options.delimiter;
    result += scope.$eval(options.expression, { $resource: list[i] });
    i++;
  }
  return result;
}

export default () => ({
  restrict: "A",
  scope: { series: "=evaSeries" },
  link($scope, $element, $attrs) {
    let options = Object.entries(defaults).reduce((acc, [k, v]) => {
      acc[k] = $attrs.hasOwnProperty(k) ? $attrs[k] : v;
      return acc;
    }, {});
    options.delimiter = options.delimiter.replace(/&nbsp/g, " ");
    $scope.$watchCollection("series", (list) => {
      $element.html(build($scope, list || [], options));
    });
  }
})
