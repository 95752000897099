import { downloadFile } from 'share/services/api'

export default app => app.directive('evaDownloadFile', () => ({
  scope: false,
  link(scope, element, attrs) {
    element.attr('href', '#');
    element.on('click', e => {
      e.preventDefault();
      let loader = $('<span>').addClass('eva-loader');
      let opts = { filename: attrs.filename || element.text() };

      element.addClass('d-none');
      element.after(loader);

      let onComplete = () => {
        element.removeClass('d-none');
        loader.remove();
      };

      downloadFile(attrs.evaDownloadFile, opts).then(onComplete, onComplete);
    })
  }
}));
