import { fetchRelationIds } from 'share/services/api'

export default ['Resty', Resty => Resty('document_groups', klass => {
  klass.tableList = (page, options = {}) =>
    klass.page(page, { type: 'local', ...options });

  klass.getLocalList = () => klass.getList({ type: 'local' });

  klass.getDetailed = id => Promise.all([
    klass.fetchOne(id),
    fetchRelationIds(`/document_groups/${id}/roles.ids`),
    fetchRelationIds(`/document_groups/${id}/user_groups.ids`)
  ]).then(([documentGroup, roleIds, userGroupIds]) => {
    documentGroup.roleIds = roleIds;
    documentGroup.userGroupIds = userGroupIds;
    return documentGroup;
  });
})]
