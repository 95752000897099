import { fetchAllList, fetchListByIds, fetchOne } from 'share/services/api'
import { mapByIds } from 'share/services/utils'
import { mapTasksToList } from 'share/features/tasks/utils'

export default ['Resty', Resty => Resty('task_lists', klass => {
  Object.assign(klass, {
    tableList(page, options = {}) {
      return klass.page(page, options).then(taskLists => {
        let promises = [];
        let taskIds = taskLists.reduce((acc, taskList) => {
          promises.push(taskList.loadNestedResourcesCount('workflows'));
          return acc.concat(taskList.taskIds);
        }, []);
        promises.push(fetchListByIds('/tasks', 'tasks', taskIds).then(tasks => {
          taskLists.forEach(taskList =>
            mapTasksToList(taskList, mapByIds(tasks, taskList.taskIds), true));
        }));
        return Promise.all(promises).then(() => taskLists);
      });
    },
    loadFormData(id) {
      return Promise.all([
        fetchOne(`/task_lists/${id}`, 'task_lists', 'task_list').then(taskList => taskList.loadNestedResourcesCount('workflows')),
        fetchAllList('tasks')
      ]).then(([taskList, tasks]) => [
        { allTasks: mapTasksToList({}, tasks, true) },
        mapTasksToList(taskList, mapByIds(tasks, taskList.taskIds), true)
      ]);
    }
  });
})];
