export default () => {
  function ViewMode(callbacks) {
    this.callbacks = callbacks || {};
    this.set('init');
  }

  Object.assign(ViewMode.prototype, {
    set(state, ...args) {
      let callback = this.callbacks[state];
      if(callback) callback.apply(this, args);
      this.state = state;
    },
    reset() {
      this.set('init');
    },
    is(state) {
      return this.state == state;
    },
    isInit() {
      return this.is('init');
    }
  });
  return ViewMode;
}
