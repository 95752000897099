import noop from 'lodash-es/noop'
import toastr from 'share/services/toastr'

const onClass = 'ngToggle--on';
const offClass = 'ngToggle--off';
const ngModelStub = { $setViewValue: noop };

export default () => ({
  require: '?ngModel',
  link($scope, $element, $attrs, ngModel) {
    let value = $scope.$eval($attrs.ngToggle);
    let model = ngModel || ngModelStub;

    $element.addClass('ngToggle').addClass(value ? onClass : offClass);
    if ($attrs.hasOwnProperty('disabled')) {
      $element.addClass('ngToggle--disabled');
      $element.attr('href', '#');
    } else {
      $element.attr('data-remote', true).attr('data-method', 'patch');
    }
    model.$setViewValue(value);

    const onValueChange = (newValue) => {
      if(newValue) {
        $element.addClass(onClass).removeClass(offClass);
      } else {
        $element.addClass(offClass).removeClass(onClass);
      }
      model.$setViewValue(newValue);
      value = newValue;
    }

    $scope.$watch($attrs.ngToggle, onValueChange);

    $element.on('ajax:beforeSend', (_, promise, xhr) => {
      let newValue = !value;
      $element.hide();
      xhr.url = xhr.url + '?' + $.param({ value: newValue });

      promise.then(() => {
        onValueChange(newValue);
        $scope.$digest();
      }, resp => {
        if (!$attrs.hasOwnProperty('ngAjaxLink')) {
          let json = (resp.responseJSON || {});
          let errorMessage = json.error || json.error_message;
          if(errorMessage) {
            toastr(errorMessage, { theme: 'danger' });
          }
        }
      }).always(() => $element.show());

      return true;
    }).on('click', e => e.preventDefault());
  }
});
