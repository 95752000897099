import evaImportStatus from 'raw-loader!./eva-import-status.html'
import evaLogResources from 'raw-loader!./eva-log-resources.html'
import evaStructureLog from 'raw-loader!./eva-structure-log.html'
import evaWorkflowSectors from 'raw-loader!./eva-workflow-sectors.html'

export default {
  'eva-import-status': evaImportStatus,
  'eva-log-resources': evaLogResources,
  'eva-structure-log': evaStructureLog,
  'eva-workflow-sectors': evaWorkflowSectors
}
