import { sortBySortKeyTName } from '../../services/utils'

const mapTaskToList = (taskList, task) => {
  switch (task.$type) {
    case 'reminder':
      taskList.$reminders.push(task);
      return taskList.$reminders;
      case 'guard_tour':
      case 'task':
        taskList.$tasks.push(task);
        return taskList.$tasks;
  }
}

export const mapTasksToList = (taskList, tasks, init = false) => {
  if (init) {
    taskList.$tasks = [];
    taskList.$reminders = [];
  }
  tasks.forEach(mapTaskToList.bind(null, taskList));
  taskList.$tasks.sort(sortBySortKeyTName);
  taskList.$reminders.sort(sortBySortKeyTName);
  return taskList;
}

export const addNewTaskToList = (taskList, task) => {
  let list = mapTaskToList(taskList, task);
  if (list) {
    list.sort(sortBySortKeyTName);
  }
}

export const getTaskInfo = task => {
  let modelName, icon, tooltip;
  switch (task.$type) {
    case 'reminder':
      icon = 'info-circle';
      modelName = 'reminder_task';
      break;
    case 'task':
      icon = 'list';
      modelName = 'task';
      break;
    case 'guard_tour':
      if (task.scanRequired) {
        modelName = 'guard_tour_task';
        tooltip = `enums.scan_type.${task.$scanType}`;
        switch (task.$scanType) {
          case 'barcode':
          case 'qrcode':
            icon = task.$scanType;
            break;
          case 'nfc':
            icon = 'wifi';
            break;
          default:
            icon = 'check-square';
        }
      } else {
        modelName = 'task';
        icon = 'list';
      }
      break;
    default:
      icon = 'question';
      modelName = 'task';
  }

  return { icon, modelName, tooltip: tooltip || `models.${modelName}.one` };
}
