import { getFileUrl } from '../services/api'

export default app => app.directive('evaLocationLogo', () => ({
    restrict: 'E',
    template: '<img />',
    replace: true,
    scope: false,
    link(_, element, attrs) {
      let url = `/${attrs.type}/${attrs.resourceId}/logo`;
      if (attrs.hasOwnProperty('force')) {
        url = `${url}?rev=${Date.now()}`;
      }
      getFileUrl(url).then(imgSrc => element.attr({ src: imgSrc, class: attrs.class }));
    }
}));
