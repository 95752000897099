import ReactDom from 'react-dom'

export default () => ({
  restrict: "E",
  scope: { props: '=', component: '=' },
  link($scope, $element) {
    let Component = $scope.component;
    ReactDom.render(<Component {...$scope.props} />, $element[0]);
  }
})
