import moment from 'moment-timezone'
import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'

if (gon.app.timezone) {
  moment.tz.setDefault(gon.app.timezone);
}

export const FORMATS = {
  datetime: 'DD.MM.YYYY HH:mm',
  datetime_s: 'DD.MM.YYYY HH:mm:ss',
  date: 'DD.MM.YYYY',
  time: 'HH:mm',
  // difference between moment & date-fns
  datetimeFns: 'dd.MM.yyyy HH:mm',
  dateFns: 'dd.MM.yyyy'
}

const LOCAL_TZ = moment.tz.guess();

export const toDate = value => moment(value);
const toLDate = value => moment.tz(value, LOCAL_TZ);
export const unixToDate = value => moment.unix(value);

// it transforms time from a location zone into a client zone
// ruby utc offset is positive in seconds
// js utc offset is negative in minutes
const ZONE_DIFF = gon.app.timeOffset ? (-(new Date().getTimezoneOffset() * 60) - gon.app.timeOffset) * 1000 : 0;
export const unixToLDate = value => new Date(value * 1000 - ZONE_DIFF);
export const unixFromLDate = date => Math.round((date.valueOf() + ZONE_DIFF) / 1000)

export const unixStartOfDay = value => unixFromLDate(startOfDay(unixToLDate(value)));
// endOfDay -> Tue Sep 02 2014 23:59:59.999, miliseconds + round gives next day
export const unixEndOfDay = value => unixFromLDate(endOfDay(unixToLDate(value))) - 1;

export const toTimeStr = (value, kind) => toDate(value).format(FORMATS[kind] || kind);
export const toLTimeStr = (value, kind) => toLDate(value).format(FORMATS[kind] || kind);
export const unixToTimeStr = (value, kind) => unixToDate(value).format(FORMATS[kind] || kind);

//- deprecate
export const toDateTimeStr = value => toDate(value).format(FORMATS.datetime)
export const toLDateTimeStr = value => toLDate(value).format(FORMATS.datetime)

export const unixToDateTimeStr = value => unixToDate(value).format(FORMATS.datetime);
export const unixToDateStr = value => unixToDate(value).format(FORMATS.date);
//- deprecate

export const toTimeDigetStr = value => value < 10 ? `0${value}` : '' + value;
export const secondsToTimeStr = value => `${toTimeDigetStr(Math.floor(value / 3600))}:${toTimeDigetStr(Math.floor((value / 60) % 60))}`

const UTC = [
  "UTC", "UTC+1", "UTC+2", "UTC+3", "UTC+4", "UTC+5", "UTC+6",
  "UTC+7", "UTC+8", "UTC+9", "UTC+10", "UTC+11", "UTC+12",
  "UTC-1", "UTC-2", "UTC-3", "UTC-4", "UTC-5", "UTC-6",
  "UTC-7", "UTC-8", "UTC-9", "UTC-10", "UTC-11", "UTC-12"
];

export const zonesForCountry = code => moment.tz.zonesForCountry(code) || UTC;

export const toUnixPeriod = (start, end) => [
  moment().add(start, 'day').startOf('day').unix(),
  moment().add(end, 'day').endOf('day').unix()
];

export const inRange = (from, to, dateGap, oldFrom, oldTo) => {
  if (from === null) {
    return [to - dateGap, to];
  } else if (to === null) {
    return [from, from + dateGap];
  } else if (from === oldFrom) {
    return [Math.max(oldFrom, to - dateGap), to];
  } else {
    return [from, Math.min(oldTo, from + dateGap)];
  }
}

export const toUnixValue = value => (value === null) ? null : Math.floor(value / 1000);

export const datesToUnixPeriod = (start, end) => [
  moment(start).startOf('day').unix(),
  moment(end).endOf('day').unix()
];
