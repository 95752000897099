export default ['Resty', 'SectorDataLoader', 'AlarmSector', (Resty, SectorDataLoader, AlarmSector) => {
  return Resty('room_sectors', klass => {
    Object.assign(klass, {
      tableList(page) {
        return klass.page(page).then(roomSectors => AlarmSector.loadListBelongsTo(roomSectors, 'alarmSector', 'alarmSectorId'));
      },

      getFormData(id) {
        return SectorDataLoader('room', id ? klass.fetchOne(id) : null);
      }
    });
  });
}];
