const isMatched = (attr, q) => attr.toLowerCase().indexOf(q) > -1;

const userMatched = (user, q) =>
  isMatched(user.firstName, q) ||
  isMatched(user.lastName, q) ||
  // sector insiders don't have an email
  isMatched((user.email || ''), q);

const search = (list, q) => {
  if (q === '' || q === null) {
    return [].concat(list);
  }
  return list.filter(user => userMatched(user, q.toLowerCase()));
}

export default () => ({
  restrict: 'E',
  template(element) {
    return `
    <div>
      <div class="row">
        <div class="col-xs-3">
          <ng-search-line callback="search"/>
        </div>
      </div>
      ${element.html()}
    </div>
`;
  },
  replace: true,
  scope: true,
  controller: ['$scope', '$attrs', ($scope, $attrs) => {
    let list = $scope.$eval($attrs.list);

    $scope.search = q => {
      $scope.searchResult.length = 0;
      $scope.searchResult.push(...search(list, q));
    }

    $scope.searchResult = [];
    $scope.search('');
  }]
})
