import { I18n } from '../../services/i18n'
import { camelize } from '../../services/utils'
import listFilterFactory from '../../services/listFilterFactory'
import { replaceBy } from '../../services/array-utils'
import sortBy from 'lodash-es/sortBy'

export default () => {
  let filter = (resource, route) => {
    if (route == 'all') return true;

    return resource.route === route;
  }

  return (initRoutes, sorting) => {
    let pairs = [
      [I18n.t('form.all'), 'all']
    ].concat(initRoutes.map(key => ([I18n.t(`models.${key.replace(/s$/, '')}.other`), key])));
    let filters = listFilterFactory(pairs, filter);

    filters.reset = () => filters.current = filters[0];
    filters.order = (resources) => sortBy(resources, sorting);
    filters.apply = (resources) => filters.current.apply(resources);
    filters.join = (source, keys) => sortBy(keys.flatMap(key => source[key] || []), sorting);
    filters.updateByForm = (owner, list, routes = initRoutes) => {
      let unsorted = list.reduce((acc, resource) => {
        if(!acc[resource.route]) acc[resource.route] = [];

        acc[resource.route].push(resource);
        return acc;
      }, {});
      routes.forEach((route) => replaceBy(owner[`$${camelize(route)}`], filters.order(unsorted[route] || [])));
      filters.reset();
    }
    filters.reset();
    return filters;
  }
}
