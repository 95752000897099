import { I18n } from '../services/i18n'
import confirm from '../services/confirm'
import { commit } from '../services/rails-fetch'

export default ['utils', utils => ({
  link($scope, $element, $attrs) {
    $element.on('ajax:formErrors', (e, xhr) => {
      let errors = (xhr.errors.resource || []);
      if (!errors.includes('deleted')) return;
      let message = I18n.t('ui.eva_restore_form.message', { email: $scope.form.email });

      confirm(message, { size: 'md' }).then(() => {
        utils.startLoadingBar();
        let form = e.target;
        let body = new FormData(form);
        body.append('restore', true);
        commit(form.getAttribute('action'), form.getAttribute('method') || 'post', { body }).then(resp => {
          window.location = resp.headers.get('location') || $attrs.evaRestoreForm;
          utils.completeLoadingBar();
        });
      });
    });
  }
})];
