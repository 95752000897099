import PhoneLink from '../components/phone-link'

const personPhones = ({ cell, phone }) => {
  var parts = [];
  if (cell) parts.push(cell);
  if (phone) parts.push(phone);
  return parts;
}

const DELIMITTER = ' / ';

export const ng = app => app.directive('evaContactPhone', () => ({
  link(scope, element, attrs) {
    let person = scope.$eval(attrs.evaContactPhone);
    let html = personPhone(person).map(phone => `<a href="tel:${phone}">${phone}</a>`).join(DELIMITTER);
    element.html(html);
  }
}));

export default user => personPhones(user).reduce((acc, phone, i) => {
  if (0 < acc.length) acc.push(DELIMITTER);
  acc.push(<PhoneLink key={i} value={phone} />);
  return acc;
}, []);
