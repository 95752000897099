import { fetchAllList, fetchRelationIds } from 'share/services/api'
import { mapByIds } from 'share/services/utils'

export default ['Resty', Resty => Resty('tasks', klass => {
  klass.loadFormData = id => {
    let promises = [
      Promise.all([
        fetchAllList('documents'),
        fetchAllList('task_lists')
      ]).then(([documents, taskLists]) => ({ documents, taskLists }))
    ];

    if(id) {
      promises.push(
        klass.fetchOne(id),
        fetchRelationIds(`/tasks/${id}/task_lists.ids`),
        fetchRelationIds(`/tasks/${id}/documents.ids`)
      );
    }

    return Promise.all(promises).then(([dict, task, taskListIds, documentIds]) => {
      if(task) {
        task.$documents = mapByIds(dict.documents, documentIds);
        task.$taskLists = mapByIds(dict.taskLists, taskListIds);
        return [dict, task];
      } else {
        return [dict];
      }
    });
  }
})]
