import { fetchAllList } from 'share/services/api'

const getFormData = () => Promise.all([
  fetchAllList('building_sectors'),
  fetchAllList('alarm_sectors'),
  fetchAllList('room_sectors'),
]).then(([buildingSectors, alarmSectors, roomSectors]) => {
  buildingSectors.forEach(b => {
    b.$alarmSectors = alarmSectors.filter(c => c.buildingSectorId == b.id);
    b.$alarmSectors.forEach(a => {
      a.$roomSectors = roomSectors.filter(c => c.alarmSectorId == a.id);
    })
  });
  return { buildingSectors, alarmSectors, roomSectors };
});

export default ['Resty', Resty => Resty('route_maps', klass => Object.assign(klass, {
  getFormData,
  getDetailed(id) {
    return Promise.all([klass.fetchOne(id), getFormData()]).then(([routeMap, dict]) => {
      let b = routeMap.$buildingSector = dict.buildingSectors.find(c => c.id == routeMap.buildingSectorId);
      let a = routeMap.$alarmSector = b.$alarmSectors.find(c => c.id == routeMap.alarmSectorId);
      if(a) routeMap.$roomSector = a.$roomSectors.find(c => c.id == routeMap.roomSectorId);
      routeMap.$meta.dict = dict;
      return routeMap;
    });
  }
}))]
