import { I18n } from '../services/i18n'

export default () => ({
  scope: { limits: '=evaLocationLimits' },
  link($scope, $element) {
    let sortIndexes = Object.keys(window.EVA.licenses.limits);
    let sortLimits = (a, b) => sortIndexes.indexOf(a[0]) - sortIndexes.indexOf(b[0]);
    let list = Object.entries($scope.limits).sort(sortLimits);
    let content = list.reduce((acc, [key, value]) => {
      if (value === 0 && key === 'connectors') return acc;

      let strValue = (value === null) ? I18n.t('ui.eva_license.counts.unlimited') : value;
      let label = `<span class="colon">${I18n.t(`ui.eva_license.limits.${key}`)}</span>`;
      return acc + (key === 'sms_configs' ? '<li class="hrb mv-2" />' : '') +`<li>${label}${strValue}</li>`;
    }, '');
    $element.html(content);
  }
})
