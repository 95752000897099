export const deleteItem = (list, item) => {
  let i = list.indexOf(item);
  if (i !== -1) list.splice(i, 1);
  return list;
}

export const replaceBy = (left, right) => {
  left.length = 0;
  left.push(...right);
}
