import { zonesForCountry } from '../services/time'

export default ['Resty', Resty => Resty('countries', 'dictionaries', klass => {
  klass.getListWithZones = () => klass.fetchList().then(countries => {
    countries.forEach(country => {
      country.$timezones = zonesForCountry(country.id);
    });

    return countries;
  });
})];
