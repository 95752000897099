import { forResource, forRoute } from '../../services/forms'

export const ng = app => {
  app.service("utils", ["cfpLoadingBar", "EvaUploader", "paginators", "ViewMode",
    (loadingBar, EvaUploader, paginators, ViewMode) => {
      return {
        formForKlass: function(klass) {
          return this.formForRoute(klass.route());
        },
        formForResource: (item, ...list) => forResource(item, list),
        formForRoute: forRoute,
        startLoadingBar: function() {
          loadingBar.start();
        },
        completeLoadingBar: function() {
          loadingBar.complete();
        },
        newViewMode: function(config) {
          return new ViewMode(config);
        },
        paginateArray: function() {
          return paginators.forArray.apply(paginators, arguments);
        },
        paginateNestedRoute() {
          return paginators.forNestedRoute.apply(paginators, arguments);
        },
        paginateTable: function() {
          return paginators.forTable.apply(paginators, arguments);
        },
        reloadInfo: function(resource, mode, callback) {
          return function() {
            loadingBar.start();
            resource.reload().then(function() {
              if(mode) mode.reset();
              if(callback) callback(resource);
              loadingBar.complete();
            });
          }
        },
        uploader: function() {
          return EvaUploader.apply(EvaUploader, arguments);
        }
      }
    }
  ]);
}
