import { fetchList, fetchPage, fetchOne } from '../services/api'
import { camelize } from '../services/utils'
import pluralize from 'pluralize'

const setNestedResourceCount = (resource, route, total) => {
  resource[`$${camelize(route)}Count`] = total;
}

const updateResource = (resource, data) => {
  let route = pluralize(resource.route, 2);
  Object.keys(window.EVA.schema[route]).forEach(key => resource[key] = data[key])
  window.app.schema.processes.$handle(resource, resource.route);
  return resource;
}

export default class {
  isA(route) {
    return this.route === route;
  }

  loadNestedResources(route, config) {
    return fetchList(`${this.itemPath()}/${route}`, route, config).then(list => {
      this[`$${camelize(route)}`] = list;
      setNestedResourceCount(this, route, list.$meta.total || list.length);
      return list;
    });
  }

  loadNestedResourcesCount(route) {
    return fetchPage(`${this.itemPath()}/${route}`, route, { page: 1, per_page: 1 }).then(({ total, list }) => {
      setNestedResourceCount(this, route, total || list.length);
      return this;
    });
  }

  reload(url) {
    return fetchOne(url || this.itemPath(), this.route, pluralize(this.route, 1)).then(data => updateResource(this, data));
  }

  applyChanges(data) {
    updateResource(this, data);
  }

  itemPath() {
    return this.$meta && this.$meta.$path || `/${this.route}/${id}`;
  }
}
