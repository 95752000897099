import { mapByIds } from '../services/utils'

class Cache {
  get(key) {
    if (!this.hasOwnProperty(key)) this[key] = [];
    return this[key]
  }

  reset(key) {
    this.get(key).length = 0;
  }
}

export default () => ({
  transclude: 'element',
  scope: { config: '=evaFilterBlock' },
  link($scope, $element, $attrs, ctrl, transclude) {
    var config = $scope.config;

    $scope.config.dict().then(dict => {
      transclude((node, $scope) => {
        $scope.dict = dict;
        var filters = $scope.filters = config.filters;;
        $scope.config = config;
        var cache = new Cache();

        $scope.reset = (...keys) => keys.forEach(key => {
          cache.reset(key);
          delete filters[key]
        });

        $scope.isInit = key => !filters.hasOwnProperty(key);

        $scope.isFilterInclude = (key, value) =>
          filters.hasOwnProperty(key) && filters[key].includes(value);

        $scope.selectedSeria = (key, list) => {
          var result = cache.get(key);
          if (result.length === 0) {
            result.splice(0, 0, ...mapByIds(list, filters[key]));
          }
          return result;
        }

        $scope.toggleFilterItem = (key, value) => {
          cache.reset(key);

          var list = filters[key];
          if (!list) {
            filters[key] = [value];
            return;
          }

          var i = list.indexOf(value);
          if (i === -1) {
            list.push(value);
            return;
          }

          if (list.length === 1) {
            delete filters[key];
          } else {
            list.splice(i, 1);
          }
        }

        var $body = $('body');
        node.on('click', '[eva-filter-button]', e => {
          e.stopPropagation();

          if (node.hasClass('open')) {
            config.callback();
            node.removeClass('open');
            $body.off('click.evaFilterBlock');
          } else {
            node.addClass('open');
            $('body').on('click.evaFilterBlock', e => {
              var $target = $(e.target);
              if ($target.parents().index(node[0]) >= 0) return;

              config.callback();
              node.removeClass('open');
              $body.off('click.evaFilterBlock');
            });
          }
        });

        $element.after(node)
      });
    });
  }
});
