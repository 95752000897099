import Pagination from '../components/pagination'
import createReactRoot from '../services/createReactRoot'

const opts = { perPage: 25, startPage: 1, padding: 3, sidePadding: 3 };

export const reloader = function(collection) {
  var page = collection.$meta.page;
  if(collection.length == 1) page -= 1;
  if(page < 1) page = 1;
  this(page);
}

const pageFromArray = (list, page) => {
  var i = (page - opts.startPage) * opts.perPage;
  if (i >= list.length)
    i = Math.max((list.length - opts.perPage), 0);
  var end = i + opts.perPage, result = [], item;
  while (i < end) {
    item = list[i];
    if (item) {
      result.push(item);
      i++;
    } else {
      break;
    }
  }
  result.$meta = { total: list.length, page: page };
  return result;
};

export default app => {
  app.service('pageFromArray', function() { return pageFromArray })

  .service('paginators', ['cfpLoadingBar', (loadingBar) => {
    return {
      forArray(dictionary, callback) {
        let paginator = page => {
          loadingBar.start();
          callback(pageFromArray(dictionary, page));
          loadingBar.complete();
        }
        paginator.onDelete = $index => {
          dictionary.splice($index, 1);
          paginator(1);
        }
        paginator(1);
        return paginator;
      },
      forNestedRoute(resource, route, callback) {
        let paginator = (page) => {
          loadingBar.start();
          resource.loadNestedResources(route, { page, per_page: opts.perPage }).then((...args) => {
            callback(...args);
            loadingBar.complete();
          });
        };
        paginator.reload = reloader;
        paginator(1);
        return paginator;
      },
      forTable(klass, ...config) {
        let callback, options;
        if(config.length == 2) {
          options = config[0];
          callback = config[1];
        } else {
          options = {};
          callback = config[0];
        }

        let initPage = options.page || 1;
        delete options.page;
        let paginator = page => {
          loadingBar.start();
          klass.tableList(page, options).then((...args) => {
            if(callback) callback.apply(null, args);
            loadingBar.complete();
          });
        }
        paginator.reload = reloader;
        paginator(initPage);
        return paginator;
      }
    };
  }])

  .directive('ngPagination', () => {
    return {
      restrict: "E",
      scope: { collection: "=", onPageChange: "=goToPage" },
      template: '<div />',
      link($scope, $element) {
        let meta = $scope.collection.$meta;
        if(!meta || (meta.total === undefined)) return;

        let app = createReactRoot($element[0]);
        let render = (meta) => {
          app.unmount();
          app.render(
            <Pagination
              page={meta.page}
              per_page={meta.per_page || opts.perPage}
              total={meta.total}
              onChange={$scope.onPageChange}
            />
          );
        }
        render(meta);
        $scope.$watch("collection.$meta", meta => render(meta))
      }
    };
  });
}
