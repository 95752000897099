import { fetchListByIds, fetchList, fetchOne, fetchPage } from '../services/api'
import { findById } from '../services/utils'
import pluralize from 'pluralize'

// per page is expected to be 25
// (but it's dangerous assumption should be removed from system)
const preparePaginationOptions = (page = 1, configs = {}) => Object.assign({ page: page, per_page: 25 }, configs);

class Resty {
  constructor(route, namespace) {
    this.$route = route;
    this.namespace = namespace;
    this.endpoint = namespace ? `/${namespace}/${route}` : `/${route}`;
  }

  fetchList(config = {}) {
    return fetchList(this.endpoint, this.$route, config);
  }

  getList(...args) {
    return this.fetchList(...args);
  }

  fetchOne(id, key = null) {
    return fetchOne(`${this.endpoint}/${id}`, this.$route, key || pluralize(this.$route, 1));
  }

  page(page, config) {
    return fetchPage(this.endpoint, this.$route, preparePaginationOptions(page, { per_page: 25, ...config })).then(({ list }) => list, Promise.reject);
  }

  getListByIds(ids) {
    return fetchListByIds(this.endpoint, this.$route, ids);
  }

  loadListBelongsTo(slaveList, property, idProperty) {
    let ids = slaveList.map(r => r[idProperty]);
    return this.getListByIds(ids).then(masterList => {
      let attrName = `$${property}`;
      slaveList.forEach(slave => slave[attrName] = findById(masterList, slave[idProperty], null));
      return slaveList;
    });
  }

  nestedPage(resource, page, options) {
    return resource.getList(this.route(), preparePaginationOptions(page, options));
  }

  tableList(...args) {
    return this.page(...args);
  }

  route() {
    return this.$route;
  }
}

export default (route, ...rest) => {
  let namespace, callback;

  if ((rest.length === 1) && (typeof rest[0] === 'function')) {
    callback = rest[0];
  } else {
    namespace = rest[0], callback = rest[1];
  }

  let service = new Resty(route, namespace);

  if (callback) callback(service);

  return service;
}
