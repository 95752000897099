import { fetchList, fetchRelationIds } from 'share/services/api'

const ROLES = ['CI', 'CSI'];

export default ['Resty', Resty => Resty('permission_groups', klass => {
  Object.assign(klass, {
    getFormData(id) {
      let resourcePromise, userIdsPromise;
      if (id) {
        resourcePromise = klass.fetchOne(id);
        userIdsPromise = fetchRelationIds(`/permission_groups/${id}/users.ids`);
      } else {
        resourcePromise = userIdsPromise = null;
      }
      return Promise.all([
        fetchList('/users', 'users').then(list => list.filter(u => ROLES.includes(u.roleId))),
        resourcePromise,
        userIdsPromise
      ]).then(([users, resource, userIds]) => {
        if (resource) {
          resource.$users = users.filter(u => userIds.includes(u.id));
        }
        return [{ users }, resource]
      });
    }
  });
})]
