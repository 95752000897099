import { fetchList, fetchOne } from '../services/api'
import uniqBy from 'lodash-es/uniqBy'

export const fetchAdminRoles = () => Promise.all([
  fetchList('/dictionaries/admin_roles', 'roles', {}, 'admin_roles'),
  fetchList('/dictionaries/roles', 'roles')
]).then(([adminRoles, roles]) =>
  uniqBy([].concat(adminRoles, roles)).sort((a, b) => a.id.localeCompare(b.id)));

export const fetchDictData = (userPromise, isAdmin) => Promise.all([
  fetchList('/dictionaries/langs', 'langs'),
  isAdmin ? fetchAdminRoles() : fetchList('/dictionaries/roles', 'roles'),
  isAdmin ? fetchList('/companies', 'companies') : null,
  userPromise
]).then(([langs, roles, companies, user]) => {
  let dict = { companies, langs, roles };

  if (user !== null) {
    user.$role = roles.find(c => c.id === user.roleId);
    user.$lang = langs.find(c => c.id === user.langId);
    if (isAdmin && user.companyId) {
      user.$company = companies.find(c => c.id === user.companyId);
    }
  }
  return [dict, user];
});

export default app => app.service('User', ['Resty', Resty => Resty('users', klass => {
  klass.loadFormData = id => fetchDictData(id ? klass.fetchOne(id) : null);
  klass.getOne = id => klass.fetchOne(id).then(user =>
    Promise.all([
      fetchOne(`/dictionaries/roles/${user.roleId}`, 'roles', 'role'),
      fetchOne(`/dictionaries/langs/${user.langId}`, 'langs', 'lang'),
    ]).then(([role, lang]) => {
      user.$role = role;
      user.$lang = lang;
      return user;
    })
  );
  return klass;
})]);
