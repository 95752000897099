import { fetchAllList, fetchRelationIds } from 'share/services/api'
import { mapByIds } from 'share/services/utils'

export default ['Resty', (Resty) => Resty('contact_lists', klass => Object.assign(klass, {
  loadFormData(id) {
    let dictPromise = Promise.all([
      fetchAllList('contacts'),
      fetchAllList('users'),
      fetchAllList('sector_insiders')
    ]);
    let resourcePromise;
    if(id) {
      resourcePromise = klass.fetchOne(id).then(contactList => Promise.all([
        contactList.loadNestedResourcesCount('workflows'),
          fetchRelationIds(`/contact_lists/${id}/contacts.ids`).then(ids => contactList.$contactIds = ids),
          fetchRelationIds(`/contact_lists/${id}/users.ids`).then(ids => contactList.$userIds = ids),
          fetchRelationIds(`/contact_lists/${id}/sector_insiders.ids`).then(ids => contactList.$sectorInsiderIds = ids)
      ]).then(() => contactList));
    }

    return Promise.all([dictPromise, resourcePromise]).then(([[contacts, users, sectorInsiders], contactList]) => {
      if(contactList) {
        contactList.$contacts = mapByIds(contacts, contactList.$contactIds);
        contactList.$users = mapByIds(users, contactList.$userIds);
        contactList.$sectorInsiders = mapByIds(sectorInsiders, contactList.$sectorInsiderIds);
      }
      return { dict: { contacts, users, sectorInsiders }, contactList };
    });
  }
}))];
