import { attachTooltip } from 'share/components/tooltip'
import { getTaskInfo } from 'share/features/tasks/utils'

export default () => ({
  scope: { task: '=evaTaskInfo' },
  link($scope, $element, $attrs) {
    let { icon, tooltip } = getTaskInfo($scope.task);
    $element.addClass(`text-primary fa fa-${icon}`);
    attachTooltip($element[0], tooltip, 'top');
  }
})
