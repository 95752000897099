export default () => ({
  link: function($scope, $element, $attrs) {
    if($element.next('[ng-variants-block]').length > 0) return;

    let $block = $(`
<div class="input-autocomplete" ng-variants-block>
  <span/>
  <ul>
    ${JSON.parse($attrs.ngVariants).map(variant => `<li>${variant}</li>`).join('')}
  </ul>
</div>`);
    $block.on('click', 'span', () => $block.toggleClass('input-autocomplete--open'))
      .on('click', 'li', function() {
        $element.val($(this).text());
        $block.removeClass('input-autocomplete--open');
      });

    $element.after($block);
  }
});
