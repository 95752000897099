import { get } from 'share/services/api'

const roundRate = number => Math.round(number * 100) / 100;
const mapProgress = (acc, data) => {
  acc.count = data.count || 0;
  acc.meanTime = roundRate(data.mean_time || 0);
  acc.rate = roundRate(data.rate || 0);
  return acc;
}

const METHODS = ['OPTIONS', 'GET', 'POST', 'PATCH', 'DELETE'];
const fetchStatistics = () => get('/dictionaries/statistics').then(resp => {
  let stats = resp.json.statistics;
  var methods = METHODS.reduce((acc, name) => {
    if (stats.hasOwnProperty(name)) {
      acc.push(mapProgress({ name }, stats[name]));
    }
    return acc;
  }, []);

  return {
    total: mapProgress({ time: (stats.globals || {}).interval }, stats.total || {}),
    methods: methods
  }
});

export default () => ({
  get() {
    return fetchStatistics();
  }
});
