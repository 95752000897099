import "share/angular"
import "ng/schema"

import evaSeries  from 'directives/eva-series'
import "script-loader!ng/directives/eva-alarm-icon"
import evaImportStatus from "ng/directives/eva-import-status"
import "script-loader!ng/directives/eva-import"
import { evaMulti, evaMultiSelector } from "ng/directives/eva-multi"
import evaDownloadFile from "ng/directives/eva-download-file"
import evaLinkGroup from 'directives/eva-link-group'
import evaGroupName from 'ng/directives/evaGroupName'
import evaLimitTo from 'ng/directives/eva-limit-to'
import "script-loader!ng/directives/eva-resource-count"
import evaUserSearch from "ng/directives/eva-user-search"
import evaTaskInfo from 'ng/directives/eva-task-info'
import "script-loader!ng/directives/fa-caret"
import "script-loader!ng/directives/ng-ajax-inline-form"
import "script-loader!ng/directives/ng-search-line"
import ngToggle from "ng/directives/ng-toggle"

import evaCheckAllowed from 'ng/directives/eva-check-allowed'

import AlarmSector from "ng/repositories/alarm-sector"
import AlarmType from "ng/repositories/alarm-type"
import Alarm from "ng/repositories/alarm"
import BuildingSector from "ng/repositories/building-sector"
import ContactList from "ng/repositories/contact-list"
import "script-loader!ng/repositories/contact"
import Document from "ng/repositories/document"
import DocumentGroup from "ng/repositories/document-group"
import "script-loader!ng/repositories/espa-route"
import "script-loader!ng/repositories/external-unit-type"
import "script-loader!ng/repositories/external-unit"
import FireDepartmentPlan from "ng/repositories/fire-department-plan"
import Location from "ng/repositories/location"
import OncallGroup from "ng/repositories/oncall-group"
import PermissionGroup from 'ng/repositories/permission-group'
import RoomSector from "ng/repositories/room-sector"
import RouteMap from "ng/repositories/route-map"
import SectorInsider from "ng/repositories/sector-insider"
import Statistics  from "ng/repositories/statistics"
import TaskList from 'ng/repositories/task-list'
import Task from "ng/repositories/task"
import TWSUser from "ng/repositories/tws-user"
import UserGroup from 'ng/repositories/user-group'
import Workflow from "ng/repositories/workflow"

import SectorDataLoader from "ng/services/sector-data-loader"
import UserDashboard from "ng/services/user-dashboard"

import templates from "ng/templates"

app.directive('evaCheckAllowed', evaCheckAllowed);
evaDownloadFile(app);

app.directive('evaGroupName', evaGroupName)
   .directive('evaLinkGroup', evaLinkGroup)
   .directive('evaLimitTo', evaLimitTo)
   .directive('evaTaskInfo', evaTaskInfo)
   .directive('evaUserSearch', evaUserSearch)
   .directive('ngToggle', ngToggle)
   .directive('evaMulti', evaMulti)
   .directive('evaMultiSelector', evaMultiSelector)
   .directive('evaSeries', evaSeries)
   .directive('evaImportStatus', evaImportStatus);

app.service('AlarmSector', AlarmSector)
   .service('Alarm', Alarm)
   .service('AlarmType', AlarmType)
   .service('BuildingSector', BuildingSector)
   .service('ContactList', ContactList)
   .service('DocumentGroup', DocumentGroup)
   .service('Document', Document)
   .service('FireDepartmentPlan', FireDepartmentPlan)
   .service("Location", Location)
   .service('OncallGroup', OncallGroup)
   .service('PermissionGroup', PermissionGroup)
   .service('RoomSector', RoomSector)
   .service('RouteMap', RouteMap)
   .service('UserGroup', UserGroup)
   .service('SectorDataLoader', SectorDataLoader)
   .service('SectorInsider', SectorInsider)
   .service('Statistics', Statistics)
   .service('TaskList', TaskList)
   .service('Task', Task)
   .service('TWSUser', TWSUser)
   .service('UserDashboard', UserDashboard)
   .service('Workflow', Workflow);

app.cacheTemplates(templates);
