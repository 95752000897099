import { Fragment } from 'react'
import { Translate } from 'share/services/i18n'
import { TooltipWrap } from 'share/components/tooltip'

export default ({ group }) => {
  let icon, affix, info;
  if(group.isRemote) {
    icon = (
      <TooltipWrap
        value="enums.type.remote"
        render={ref => <span ref={ref} className="evi evi--g-remote link mr-1" />}
      />
    );
    affix = (
      <div className="small">
        <Translate value="models.location.one" className="colon" />
        {group.remoteName}
      </div>
    );
  } else if (group.$connector) {
    icon = (
      <TooltipWrap
        value="attrs.connector"
        render={ref => <span ref={ref} className="evi evi--g-connector link mr-1" />}
      />
    );
  } else if (group.$connected) {
    icon = (
      <TooltipWrap
        value="attrs.connector"
        render={ref => <span ref={ref} className="evi evi--g-connected link mr-1" />}
      />
    );
    info = (
      <TooltipWrap
        value="tooltip.connected_group"
        render={ref => <span ref={ref} className="control-info ml-1" />}
      />
    )
    affix = (
      <div className="small">
        <Translate value="models.location.one" className="colon" />
        {group.connectorLocationName}
      </div>
    );
  }

  return (
    <Fragment>
      <label>{icon}{group.name}{info}</label>
      {affix}
    </Fragment>
  );
}
