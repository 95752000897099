import pluck from 'lodash-es/map'
import { deleteItem, replaceBy } from '../services/array-utils'

const defaultValidation = () => true;
const getCandidates = (dictionary, selected) => {
  var uuids = pluck(selected, '$uuid');
  return dictionary.reduce((result, item) => {
    if (!uuids.includes(item.$uuid)) result.push(item);
    return result;
  }, []);
}

export default () => ({
  transclude: 'element',
  replace: true,
  scope: { selected: "=", dictionary: "=", filters: "=" },
  controller: ["$scope", "pageFromArray", "$attrs", function ($scope, paginate, $attrs) {
    var selected = $scope.selected,
      candidates = getCandidates($scope.dictionary, selected);

    var selectedPageNumber = 1, candidatePageNumber = 1,
      paginateSelected, paginateCandidate;

    if ($attrs.validate) {
      $scope.isValid = $scope.$parent[$attrs.validate];
      var invalidSelected = selected.filter((resource) => !$scope.isValid(resource, 'init'));
      invalidSelected.forEach(resource => {
        deleteItem(selected, resource);
        candidates.unshift(resource);
      });
    } else {
      $scope.isValid = defaultValidation;
    }

    $scope.updateSelectedPage = function (page) {
      selectedPageNumber = page;
      $scope.selectedPage = paginateSelected(page);
    }

    $scope.updateCandidatePage = function (page) {
      candidatePageNumber = page;
      $scope.candidatePage = paginateCandidate(page);
    }

    if ($scope.filters) {
      $scope.sfilter = $scope.cfilter = $scope.filters[0];
      $scope.$watch("sfilter", () => $scope.updateSelectedPage(1));
      $scope.$watch("cfilter", () => $scope.updateCandidatePage(1));

      paginateSelected = function (page) {
        var $$selected = $scope.sfilter.apply(selected);
        return paginate($$selected, page);
      }
      paginateCandidate = function (page) {
        var $$candidates = $scope.cfilter.apply(candidates);
        return paginate($$candidates, page);
      }
    } else {
      paginateSelected = function (page) {
        return paginate(selected, page);
      }
      paginateCandidate = function (page) {
        return paginate(candidates, page);
      }
    }

    var refreshPages = function () {
      $scope.updateSelectedPage(selectedPageNumber);
      $scope.updateCandidatePage(candidatePageNumber);
    }

    $scope.moveResource = function (resource) {
      if (selected.includes(resource)) {
        candidates.push(resource);
        deleteItem(selected, resource);
      } else {
        selected.push(resource);
        deleteItem(candidates, resource);
      }
      refreshPages();
    }

    $scope.movePage = function (page) {
      if (page == $scope.selectedPage) {
        page = page.filter(r => $scope.isValid(r, 'reject'));
        candidates.push(...page);
        page.forEach(resource => deleteItem(selected, resource));
      } else {
        page = page.filter(r => $scope.isValid(r, 'select'));
        selected.push(...page);
        page.forEach(resource => deleteItem(candidates, resource));
      }
      refreshPages();
    }

    $scope.$watchCollection("dictionary", function (dictionary) {
      replaceBy(candidates, getCandidates(dictionary, selected));
      refreshPages();
    });

    $scope.$watchCollection("selected", function () {
      refreshPages();
    });

    refreshPages();
  }],
  link: function ($scope, $element, $attrs, ctrl, transcludeFn) {
    transcludeFn($scope, clone => $element.after(clone));
  }
});
