export const evaMultiSelector = () => ({
  link(scope, element) {
    let formScope = element.parents("form").scope() || scope;
    formScope.$formLocale = element.val();

    element.on('change', () => {
      formScope.$formLocale = element.val();
      formScope.$digest();
    });
  }
});

export const evaMulti = () => ({
  transclude: 'element',
  priority: 1000,
  link(scope, element, attrs, ctrl, $transclude) {
    let formScope = element.parents('form').scope();
    if(!formScope) return;

    let current = formScope.$formLocale;
    let config = window.gon.app.locale;
    let scopes = config.list.map((locale) => {
      let childScope = scope.$new();
      childScope.$locale = locale;
      childScope.$default = config.default;
      childScope.$active = (locale == current);

      $transclude(childScope, clone => element.after(clone));
      return childScope;
    });

    formScope.$watch('$formLocale', (locale) => scopes.forEach((scope) => scope.$active = (scope.$locale == locale)));
  }
});
