import { fetchOne, fetchList } from 'share/services/api'
import { filterByIdsF } from 'share/services/utils'
import { mapDetails } from 'share/features/alarms/utils'
import { isAdmin } from 'services/policy'

const mapDetailsWithFilter = alarm => {
  mapDetails(alarm);
  alarm.$contactLists = alarm.$contactLists.filter(filterByIdsF(isAdmin() ? false : alarm.contactListIds));
  return alarm;
}

export default ['Resty', (Resty, ra) =>
  Resty('alarms', klass => {
    Object.assign(klass, {
      getOne(id, type) {
        let promise;
        switch(type) {
          case 'normal':
            promise = klass.fetchOne(id).then(mapDetailsWithFilter);
            break;
          case 'archive':
            // due to API limitation `task_lists` field is missed when fetching by id
            promise = fetchList('/alarms_archive', 'alarms', { filter: { id_in: [id] } }).then(([alarm]) => {
              alarm.$isArchive = true;
              return mapDetailsWithFilter(alarm);
            });
            break;
          case 'external':
            promise = fetchOne(`/alarms/external/${id}`, 'external_alarms', 'external_alarm').then(mapDetails);
            break;
          case 'external_archive':
            promise = fetchOne(`/alarms_archive/external/${id}`, 'external_alarms', 'external_alarm').then(mapDetails);
            break;
          default:
            throw `Unknown alarm type - ${type}`;
        }

        return promise.then(alarm => {
          alarm.$type = type;
          return alarm;
        });
      }
    });
  })
];
