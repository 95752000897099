import { mapTasksToList } from '../tasks/utils'
import { fetchList } from '../../services/api'
import { I18n } from '../../services/i18n'
import { findById, filterDeleted, tAttr, sortBySortKeyName, sortBySortKeyTName } from '../../services/utils'
import find from 'lodash-es/find'

export const toSector = ({ $uuid, id, name, route, sortKey }, children) => {
  var result = {
    $uuid, id, name, route,
    sortKey, q: name.toLowerCase(), children,
    childCount: children.length
  };
  result.children.sort(sortBySortKeyName);
  result.children.forEach(child => {
    result.childCount = result.childCount + child.childCount;
    child.parent = result;
  });
  return result;
}

export let buildMap = (list, childMap, key, ids) => list.reduce((acc, data) => {
  var sector = toSector(data, childMap[data.id] || []);
  if(!acc[data[key]]) {
    acc[data[key]] = [];
  }

  acc[data[key]].push(sector);
  if(ids.includes(sector.id)) {
    acc.$selected.push(sector);
  }

  return acc;
}, { $selected: [] });

export const mapDetails = alarm => {
  var entities = alarm.encapsulatedEntities;

  alarm.$shouldBeParticipated = alarm.$isActive && alarm.readReceiptRequested;
  alarm.$participationOptions = app.schema.normalizeList(entities.participation_options || [], 'participation_options');
  alarm.$participationOption = findById(alarm.$participationOptions, alarm.participationOptionId, null);
  alarm.$taskLists = (entities['task_lists'] || []).reduce((acc, data) => {
    let taskList = app.schema.normalize(data, 'task_lists');
    if (filterDeleted(taskList)) {
      let tasks = app.schema.normalizeList(data['tasks'] || [], 'tasks').filter(filterDeleted);
      if (0 < tasks.length) {
        mapTasksToList(taskList, tasks, true);
        acc.push(taskList);
      }
    }
    return acc;
  }, []).sort(sortBySortKeyTName);

  return alarm;
}

export const alarmLevelToStr = level => {
  let name = tAttr(level.name);
  let descr = tAttr(level.description);
  return descr ? `${name}: ${descr}` : name;
}

export const toParticipationStr = value => {
  let key;
  switch(value) {
    case true:
      key = 'accepted';
      break;
    case false:
      key = 'rejected';
      break;
    default:
      key = 'waiting';
  }
  return I18n.t(`ui.recipients.filters.${key}`);
}

const DEF_TASK_STATUS = {
  $done: false,
  $doneAt: null,
  $lastStatus: null,
  $history: []
};

const mapTaskHistoryStatus = data => ({
  $user: window.app.schema.normalize(data.initiator_info, 'user_infos'),
  action: data.action,
  message: data.message,
  doneAt: data.done_at * 1000,
  $done: data.action === 'done'
});

const mapTaskStatus = ({ done, doneAt, statuses }) => {
  var result = {
    $done: done,
    $doneAt: doneAt,
    $history: statuses.map(mapTaskHistoryStatus)
  };
  result.$lastStatus = result.$history[0] || null;
  return result;
}

export const fetchTaskStatuses = (path, taskLists) => {
  if (taskLists.length === 0) return new Promise(resolve => resolve({}));

  return fetchList(path, 'task_statuses', {}, 'tasks').then(statuses =>
    taskLists.reduce((acc, taskList) => {
      acc[taskList.id] = taskList.$tasks.reduce((tacc, task) => {
        let status = find(statuses, { taskId: task.id, taskListId: taskList.id });
        tacc[task.id] = status ? mapTaskStatus(status) : DEF_TASK_STATUS;
        return tacc;
      }, {});
      return acc;
    }, {})
  );
}
