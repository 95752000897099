import each from 'lodash-es/each'
import reduce from 'lodash-es/reduce'
import isObject from 'lodash-es/isObject'
import map from 'lodash-es/map'
import omit from 'lodash-es/omit'
import isNumber from 'lodash-es/isNumber'
import {
  findById, filterDeleted,
  sortBySortKeyName, sortBySortKeyTAttr, sortByFullName, toName
} from '../services/utils'
import Resource from './resource'
import RestyFactory from './resty-factory'

var uuid = 0;
const getUUID = () => {
  uuid = uuid + 1;
  return uuid;
}

const EDITABLE_MIMETYPES = ['application/pdf', 'image/png', 'image/jpeg'];

const capitalizeKey = str => str.slice(0, 1).toUpperCase() + str.slice(1);

const assignAlarmTypeMarkers = (holder, id) => {
  // $isEvacuation, $isSos, $isTech, $isSw
  Object.entries(window.EVA.enums.alarmTypes).forEach(([key, testId]) =>
    holder[`$is${capitalizeKey(key)}`] = id === testId);
}

const sortAttachments = (a, b) => b.updatedAt - a.updatedAt;

const mapAlarmDetails = alarm => {
  assignAlarmTypeMarkers(alarm, alarm.alarmTypeId);

  alarm.$isActive = alarm.currentStatus < 3;
  alarm.$isInfo = alarm.currentStatus === 5;
  alarm.initiator.$name = toName(alarm.initiator.firstName, alarm.initiator.lastName);

  let entities = alarm.encapsulatedEntities;
  alarm.$alarmType = app.schema.normalize(entities.alarm_type, 'alarm_types');
  alarm.$alarmLevels = app.schema.normalizeList(entities.alarm_levels, 'alarm_levels');
  alarm.$alarmLevelHistory = alarm.alarmLevelsHistory.reduce((acc, item) => {
    let base = {
      onCreation: item.createdAt === alarm.createdAt,
      createdAt: item.createdAt,
      userInfo: item.userInfo || item.userName // external alarms don't have userInfo for now
    };
    item.alarmLevelIds.forEach(id => {
      let $alarmLevel = findById(alarm.$alarmLevels, id, null);
      if ($alarmLevel) {
        acc.push({ ...base, $alarmLevel });
      }
    });
    return acc;
  }, []);


  alarm.$buildingSectors = app.schema.normalizeList(entities.building_sectors, 'building_sectors');
  alarm.$buildingSectors.sort(sortBySortKeyName);

  alarm.$alarmSectors = app.schema.normalizeList(entities.alarm_sectors, 'alarm_sectors');
  alarm.$alarmSectors.sort(sortBySortKeyName);

  alarm.$roomSectors = app.schema.normalizeList(entities.room_sectors, 'room_sectors');
  alarm.$roomSectors.sort(sortBySortKeyName);

  alarm.statuses.forEach(status => {
    status.$kind = { 2: 'confirm', 3: 'cancel', 4: 'stop' }[status.value];
    status.initiator.$name = toName(status.initiator.firstName, status.initiator.lastName);
  });

  let answersMap = alarm.infoItems.reduce((acc, item) => {
    acc[item.id] = item.answers;
    return acc;
  }, {});
  // a workaround as sometimes external alarms don't have info items
  alarm.$infoItems = (entities.info_items || []).map(data => {
    let item = app.schema.normalize(data, 'info_items');
    item.$answers = (answersMap[item.id] || []).map((answer) => {
      answer.onCreation = answer.createdAt === alarm.createdAt;
      return answer;
    });
    return item;
  });

  let [$templateAttachment, $attachments] = alarm.attachments.reduce((acc, at) => {
    at.$isEditable = EDITABLE_MIMETYPES.includes(at.mimetype);
    if (['custom', 'external'].includes(at.kind)) {
      acc[1].push(at);
    } else if (at.kind === 'alarm_type') {
      acc[0] = at;
    }
    return acc;
  }, [null, []]);

  alarm.$templateAttachment = $templateAttachment;
  alarm.$attachments = $attachments.sort(sortAttachments);
}

const mapContactList = (acc, data, contactsData) => {
  let list = app.schema.normalize(data, 'contact_lists');
  if (filterDeleted(list)) {
    list.$allContacts = app.schema.normalizeList(contactsData, 'contacts').filter(filterDeleted);
    list.$allContacts.sort(sortByFullName);
    list.$allContactsCount = list.$allContacts.length;
    acc[0].push(list);
    acc[1] = acc[1] + list.$allContactsCount;
  }
  return acc;
}

export default app => {
  app.factory('Resty', () => RestyFactory);

  // holds all resource schemas
  let schema = {
    utils: { name: toName },
    defaults: {},

    mixins: {},
    extendMixins: function(changes) {
      var mixins = this.mixins;
      each(changes, function(custom, key) {
        if(mixins.hasOwnProperty(key)) {
          mixins[key] = Object.assign({}, mixins[key], custom);
        } else {
          mixins[key] = custom;
        }
      });
    },

    processes: {
      $handle: function(resource, type) {
        each(this[type], function(process) { process(resource); });
      }
    },
    extendProcesses: function(changes) {
      var processes = this.processes;
      each(changes, function(custom, key) {
        if(processes.hasOwnProperty(key)) {
          processes[key].push(custom);
        } else {
          processes[key] = [custom];
        }
      });
    },

    collectionProcesses: {},
    extendCollectionProcesses: function(changes) {
      var processes = this.collectionProcesses;
      each(changes, function(custom, key) {
        if(processes.hasOwnProperty(key)) {
          processes[key].push(custom);
        } else {
          processes[key] = [custom];
        }
      });
    }
  };

  let endsWith = (str, suffix) => str.indexOf(suffix, str.length - suffix.length) !== -1;

  var prepareValue = function(key, value) {
    if(endsWith(key, "At") && isNumber(value))
      // time value(add to unix timestamp nanoseconds fraction)
      return value * 1000;
    else
      return value;
  }

  // convert original output to defined schema
  var nextLevel = function(prevLevel, key) { if(prevLevel) return prevLevel[key]; }
  var applySchema = function(schema, source) {
    var resource = reduce(schema, function(result, path, key) {
      if(key == "$nodes") return result;
      let value = reduce(path, nextLevel, source);
      result[key] = prepareValue(key, value);
      return result;
    }, new Resource());

    if(schema.$nodes) {
      each(schema.$nodes, function(nodeSchema, nodeKey) {
        var source = resource[nodeKey];

        if(Array.isArray(source)) {
          resource[nodeKey] = map(source, nodeSource => applySchema(nodeSchema, nodeSource));
        } else if(isObject(source)) {
          resource[nodeKey] = applySchema(nodeSchema, source);
        }
      });
    }

    return resource;
  }

  schema.normalize = (source, route) => {
    let resource = window.EVA.schema[route] ? applySchema(window.EVA.schema[route], source) : source;
    resource.$uuid = getUUID();
    resource.route = route;
    Object.assign(resource, schema.mixins[route]);
    schema.processes.$handle(resource, route);
    return resource;
  }

  schema.normalizeList = (list, route) => {
    let result = (list || []).map(item => schema.normalize(item, route));
    let processings = schema.collectionProcesses[route] || [];
    processings.forEach(process => process(result));
    return result;
  }

  schema.normalizeCollection = (source, route, key = route) => {
    let collection = schema.normalizeList(source[key], route);
    schema.meta(collection, source, key);
    return collection;
  }

  schema.meta = (holder, source, key) => holder.$meta = omit(source, key);

  schema.extendProcesses({
    alarms(alarm) {
      mapAlarmDetails(alarm);
      let entities = alarm.encapsulatedEntities;

      let [contactLists, allContactsCount] = (entities.contact_lists || []).reduce(
        (acc, data) => mapContactList(acc, data, [].concat(data.contacts, data.users, data.sector_insiders)), [[], 0]);

      alarm.$contactLists = contactLists.filter(item => item.$allContactsCount !== 0);
      alarm.$allContactsCount = allContactsCount;

      // status == 1 || 2
      if (alarm.$isSos) {
        alarm.$isHandled = (alarm.currentStatus == 4);
        alarm.$location = (alarm.infoItems[0] || { answers: [{}] }).answers[0].answer;
        alarm.handle = value => alarm.$isHandled = value;
      }
      alarm.$massStopAllowed = alarm.$alarmType.prioKey === 3 && alarm.$isActive;
    },
    alarm_types(alarmType) {
      alarmType.$isAlarm = alarmType.category == 'alarm';
      alarmType.$isInfo = alarmType.category == 'info';
      assignAlarmTypeMarkers(alarmType, alarmType.id);
      // a mobile client has a separated UI or used by API internally
      alarmType.$isTechical = alarmType.$isSw || alarmType.$isTech;
      alarmType.$isSpecific = alarmType.$isSos || alarmType.$isTechical;
    },
    confirmations(confirmation) {
      confirmation.$name = toName(confirmation.firstName, confirmation.lastName);
    },
    contacts(contact) {
      contact.$name = toName(contact.firstName, contact.lastName);
    },
    contact_lists(contactList) {
      contactList.$allContactsCount = contactList.contactsCount + contactList.usersCount + contactList.sectorInsidersCount;
    },
    external_alarms(alarm) {
      mapAlarmDetails(alarm);

      let entities = alarm.encapsulatedEntities;
      alarm.$location = schema.normalize(entities.source_origin, 'locations');

      let [contactLists, allContactsCount] = entities.contact_lists.reduce((acc, data) =>
        mapContactList(acc, data, [].concat(data.external_contacts, data.internal_contacts)), [[], 0]);

      alarm.$contactLists = contactLists;
      alarm.$allContactsCount = allContactsCount;
    },
    info_items(infoItem) {
      // convert multi list:
      // { en: [en_item1, en_item2, en_item3], de: [de_item1, de_item2, de_item3]}
      // =>
      // [{en: en_item1, de: de_item1}, {en: en_item2, de: de_item2}, {en: en_item3, de: de_item3} ]
      infoItem.answers = Object.entries(infoItem.answers).reduce((acc, [locale, list]) => {
        list.forEach((item, i) => {
          if (!acc[i]) {
            acc[i] = {};
          }
          acc[i][locale] = item;
        });
        return acc;
      }, []);
    },
    route_maps(routeMap) {
      routeMap.$isBackFileExists = !!(routeMap.backUploadedAt || routeMap.backSource);
    },
    tasks(task) {
      task.$type = window.EVA.enums.taskTypes[task.type];
      task.$scanType = window.EVA.enums.taskScanTypes[task.scanType];
    }
  });

  schema.extendMixins({
    countries: {
      isDefault() {
        return this.id == 'de';
      }
    },
    documents: {
      downloadFilePath(l, appId = null) {
        var acc = ['/documents'];
        if (appId !== null) {
          acc.push(appId)
        }
        acc.push(this.id, l, 'file');
        return `${acc.join('/')}?cb=${this.updatedAt}`;
      }
    },
    external_unit_types: {
      isDefault() {
        return this.id == '0000000001';
      }
    }
  });

  schema.extendProcesses({
    evacuation_users(user) {
      user.$name = toName(user.firstName, user.lastName);
    },
    users(user) {
      user.$name = toName(user.firstName, user.lastName);
    }
  });

  let multiLangSortFactory = (attr) => (list) => list.sort((a, b) => sortBySortKeyTAttr(a, b, attr));
  schema.extendCollectionProcesses({
    alarm_types: multiLangSortFactory('name'),
    alarm_levels: multiLangSortFactory('name'),
    info_items: multiLangSortFactory('question')
  });

  return schema;
}
