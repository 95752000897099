import { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { I18n } from '../services/i18n'
import railsFetch from '../services/rails-fetch'
import Modal from './modal'

const MAX_SIZE = { logo: 1048576, file: 5242880 };

const rejectWithAlert = key => {
  window.alert(I18n.t(`ui.eva_uploader.${key}`));
  return false;
}

export const PRESETS = {
  evaImport(file) {
    if(file.type.includes('sheet') || file.name.includes('.xlsx')) {
      return true;
    }
    return rejectWithAlert('invalid_file_format');
  },
  evaLogo(file) {
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      return rejectWithAlert('invalid_file_format');
    }

    // more than 1 MB
    if (file.size > MAX_SIZE.logo) {
      return rejectWithAlert('reached_max_file_size');
    }

    return true;
  },
  evaMap(file) {
    if ('application/pdf' !== file.type) {
      return rejectWithAlert('invalid_file_format');
    }

    if (file.size > MAX_SIZE.file) {
      return rejectWithAlert('reached_max_file_size');
    }

    return true;
  },
  evaAlarmAttachment(file) {
    if ('application/pdf' !== file.type) {
      return rejectWithAlert('invalid_file_format');
    }

    if (file.size > MAX_SIZE.file) {
      return rejectWithAlert('reached_max_file_size');
    }

    return true;
  }
}

export default class extends Component {
  static propTypes = {
    preset: PropTypes.oneOf(Object.keys(PRESETS)).isRequired
  }

  constructor(props) {
    super();
    this.state = { preview: false };
    this.preset = PRESETS[props.preset];
    this.fileInputRef = React.createRef();
    this.onFileSelect = this.onFileSelect.bind(this);
    this.onFileSelected = this.onFileSelected.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onPreviewOpen = () => this.setState({ preview: true });
    this.onPreviewClose = () => this.setState({ preview: false });
  }

  componentDidMount() {
    this.fileInputRef.current.addEventListener('change', this.onFileSelected);
  }

  onFileSelect() {
    this.fileInputRef.current.click();
  }

  onFileSelected(e) {
    let file = e.target.files[0];
    if (!(file && this.preset(file))) return;
    var body = new FormData();
    body.append('file', file);
    railsFetch(Routes.uploads(), 'POST', { body }).then(resp => resp.json()).then(data =>
      this.props.onChange({ id: data.file, name: file.name }));
    this.fileInputRef.current.value = '';
  }

  onReset() {
    this.props.onChange(null);
  }

  render() {
    let { value } = this.props;
    let fileName, previewBtn, resetBtn, modal;

    if (value) {
      fileName = value.name;
      previewBtn = (
        <span className="input-group-addon input-group-addon--info" onClick={this.onPreviewOpen}>
          <span className="fa fa-view" />
        </span>
      );
      resetBtn = (
        <span className="input-group-addon input-group-addon--danger" onClick={this.onReset}>
          <span className="fa fa-trash" />
        </span>
      );

      if (this.state.preview) {
        let previewPath = `/files/uploads/${value.id}`;
        modal = (
          <Modal>
            <a href="#" className="modal-close modal-close--preview" onClick={this.onPreviewClose}/>
            <div className="modal-body">
              <a href={previewPath} className="upload-share" target="_blank">
                <span className="fa fa-external-link" />
              </a>
              <iframe className="upload-preview" src={previewPath} />
            </div>
          </Modal>
        );
      }
    } else {
      fileName = '';
    }

    return (
      <Fragment>
        <div className="input-group input-group--file">
          <input type="file" ref={this.fileInputRef} className="hide" />
          <input type="text" className="form-control" value={fileName} readOnly />
          {previewBtn}
          <span className="input-group-addon input-group-addon--primary" onClick={this.onFileSelect}>
            <span className="fa fa-upload" />
          </span>
          {resetBtn}
        </div>
        {modal}
      </Fragment>
    );
  }
}
