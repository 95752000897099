const POLICY = gon.app.policy || { isAdmin: false, permissions: { alarms: {}, tabs: {} }, licenses: [] };

let _hasAbility, _isAllowed, _isAdmin;
if(POLICY.permissions === true) {
  _isAdmin =  _isAllowed = _hasAbility = () => true;
} else if(typeof POLICY.permissions === 'object') {
  _hasAbility = (id, action) => (POLICY.permissions.alarms[id] || {})[action] || false;
  _isAllowed = tab => {
    switch(tab) {
      case 'alarm_tasks':
      case 'alarm_archive':
      case 'evacuations':
        return ['CSI', 'GSU', 'CI'].includes(gon.app.user.role);
      default:
        return POLICY.permissions.tabs.includes(tab);
    }
  }
  _isAdmin = () => false
} else {
  throw('The permissions were not provided');
}

export const isAdmin = _isAdmin;
export const hasAbility = _hasAbility;
export const isAllowed = _isAllowed;
export const hasLicense = mod => POLICY.licenses.includes(mod);
