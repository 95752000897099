import lastItem from 'lodash-es/last'

let getPopupTemplate = (id, size, content) => {
  let cssClasses = ['modal', 'fade', 'in', 'show', 'eva-modal'];

  return `
<div class="${cssClasses.join(' ')}" id="ng-popup-${id}">
  <div class="modal-dialog modal-${size || 'md'}">
    <div class="modal-content">${content}</div>
  </div>
</div>
`
}

export default app => {
  app.directive("ngPopup", ["$compile", "$templateCache", function($compile, $templateCache) {
    var _popupId = 0, history = [];

    var $popup = function(popupId) { return $("#ng-popup-" + popupId); }

    var closePopup = function() {
      $popup(history.pop()).remove();

      if(history.length == 0) {
        $("#ng-popup-wrapper").remove();
      } else {
        $popup(lastItem(history)).addClass("show");
      }
    }

    var openPopup = function(popupId, template) {
      var wrapper;

      if(history.length == 0) {
        wrapper = $("<div>").addClass("modal-backdrop fade in").attr("id", "ng-popup-wrapper");
        $("body").append(wrapper);
      } else {
        wrapper = $("#ng-popup-wrapper");
        $popup(lastItem(history)).removeClass("show");
      }
      history.push(popupId);
      wrapper.after(template);
    }

    return {
      scope: false,
      link: function(scope, element, attrs) {
        var popupId = _popupId = _popupId + 1, popupScope, firstInit = true;

        var template = function(callback) {
          var content = $templateCache.get(attrs.ngPopup);

          if(content) {
            callback(getPopupTemplate(popupId, attrs.ngPopupSize, content));
          } else {
            $.get(attrs.ngPopupUrl).then(function(content) {
              $templateCache.put(attrs.ngPopup, content);
              callback(getPopupTemplate(popupId, attrs.ngPopupSize, content));
            });
          }
        }

        element.on("click", function() {
          template(function(popupBody) {
            // generate scope and assign popup events only once
            if(firstInit) {
              popupScope = scope.$new(false);
              popupScope.closePopup = closePopup;

              var closeSelector = "#ng-popup-" + popupId +  " [ng-popup-close]";
              $("body").on("click", closeSelector, function() {
                var trigger = $(this), callback = trigger.attr("ng-popup-close");
                if(callback) {
                  trigger.scope().$eval(callback);
                  scope.$apply();
                }

                closePopup();
              });

              firstInit = false;
            }

            openPopup(popupId, $compile(popupBody)(popupScope));
            popupScope.$digest();
          });
        });
      }
    };
  }]);
}
