import { fetchRelationIds, fetchListByRelationIds, fetchAllList } from 'share/services/api'
import { hasLicense } from 'services/policy'
import { mapByIds } from 'share/services/utils'

const buildSchema = (type, includeUsers) => {
  let schema = []
  if(includeUsers) {
    schema.push(
      {
        key: 'users',
        getList: () => fetchAllList('users'),
        assign: (sector, list) =>
          fetchRelationIds(`${sector.itemPath()}/users.ids`).then(ids => sector.$users = mapByIds(list, ids), Promise.reject)
      },
      {
        key: 'sectorInsiders',
        getList: () => fetchAllList('sector_insiders'),
        assign: (sector, list) =>
          fetchRelationIds(`${sector.itemPath()}/sector_insiders.ids`).then(ids => sector.$sectorInsiders = mapByIds(list, ids), Promise.reject)
      }
    );
  }

  switch(type) {
    case 'building':
      if(hasLicense('visitors') && includeUsers) {
        schema.push({
          key: 'visitors',
          getList: () => fetchAllList('visitors'),
          assign: (sector, list) =>
            fetchRelationIds(`${sector.itemPath()}/visitors.ids`).then(ids => sector.$visitors = mapByIds(list, ids), Promise.reject)
        });
      }
      schema.push({
        key: 'fireDepartmentPlans',
        getList: () => fetchAllList('fire_department_plans').then(list => [{ id: '', name: '', mock: true }].concat(list)),
        assign(sector, list) {
          if(sector.fireDepartmentPlanId) {
            sector.$fireDepartmentPlan = list.find(r => r.id == sector.fireDepartmentPlanId) || list[0];
          } else {
            sector.$fireDepartmentPlan = list[0];
          }
        }
      });
      schema.push({
        key: 'alarmTypes',
        getList: () => new Promise(resolve => resolve([])),
        assign: sector => fetchListByRelationIds(`/building_sectors/${sector.id}/alarm_types.ids`, '/alarm_types', 'alarm_types').
          then(list => sector.$alarmTypes = list)
      })
      break;
    case 'alarm':
      schema.push({
        key: 'buildingSectors',
        getList: () => fetchAllList('building_sectors'),
        assign: (sector, list) => sector.$buildingSector = list.find(s => s.id === sector.buildingSectorId)
      });
      break;
    case 'room':
      schema.push({
        key: 'alarmSectors',
        getList: () => fetchAllList('alarm_sectors'),
        assign: (sector, list) => sector.$alarmSector = list.find(s => s.id === sector.alarmSectorId)
      });
      break;
    default:
      throw `Invalid sector type ${type}`;
  }

  return schema;
}

export default () => (type, sectorPromise) => {
  let schema = buildSchema(type, !!sectorPromise);
  var dict = {};
  let promises = schema.map(config => config.getList().then(list => dict[config.key] = list));

  return Promise.all([Promise.all(promises), sectorPromise]).then(result => {
    let sector = result[1];
    if(sector) {
      let promises = schema.map(config => config.assign(sector, dict[config.key]));
      return Promise.all(promises).then(() => ({ dict, sector }));
    } else {
      return { dict };
    }
  });
};
