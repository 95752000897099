import { fetchList } from 'share/services/api'
import { hasLicense } from 'services/policy'
import HasManyMapper from 'share/services/mappers/has-many'
const workflowsMapper = new HasManyMapper('workflows', '$workflows');

const config = { page: 1, per_page: 3 };

export default () => ({
  get() {
    return Promise.all([
      fetchList('/users', 'users', config),
      fetchList('/user_groups', 'user_groups', config).
        then(userGroups => workflowsMapper.mapCounts(userGroups).then(() => userGroups)),
      fetchList('/oncall_groups', 'oncall_groups', config).
        then(oncallGroups => workflowsMapper.mapCounts(oncallGroups).then(() => oncallGroups)),
      hasLicense('visitors') ? fetchList('/visitors', 'visitors', config) : []
    ]).then(([users, userGroups, oncallGroups, visitors]) => ({ users, userGroups, oncallGroups, visitors}));
  }
})
