import { fetchList } from 'share/services/api'
import { unitTree } from 'share/services/units'
import findWhere from 'lodash-es/find'

export default ['Resty', 'Country', 'ExternalUnitType', (Resty, Country, ExternalUnitType) => Resty('locations', (klass) => {
  klass.getFormData = () => Promise.all([
    klass.fetchOne('current'),
    Country.getListWithZones(),
    fetchList('/locations/current/external_units', 'external_units').then((units) =>
      ExternalUnitType.loadListBelongsTo(units, 'externalUnitType', 'externalUnitTypeId').then(() => unitTree(units))
    )
  ]).then(([location, countries, externalUnitTree]) => {
    let dict = { countries };
    location.$country = findWhere(countries, { id: location.countryId });
    location.$externalUnitTree = externalUnitTree;
    return [dict, location];
  });

  klass.reloadCurrent = (location) => location.reload('/locations/current');
})];
