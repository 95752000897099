import railsFetch from 'share/services/rails-fetch'

const getStatus = (url) => railsFetch(url).then(resp => resp.json()).then(data => data || {});

export default () => ({
  restrict: 'E',
  replace: true,
  templateUrl: '/ng/eva-import-status.html',
  scope: true,
  transclude: true,
  controller: ["$scope", "$attrs", ($scope, $attrs) => {
    let timeoutId;

    let loader = () => {
      getStatus($attrs.url).then((data) => {
        $scope.status = data.status;
        $scope.info = data.info;
        $scope.errors = data.errors;

        if(data.done) {
          $scope.$eval($attrs.onEnd);
          $scope.$apply();
        } else {
          timeoutId = setTimeout(loader, 3000);
          $scope.$digest();
        }
      });
    }
    loader();

    $scope.$on('$destroy', () => {
      if(timeoutId) clearTimeout(timeoutId);
    });
  }]
});
