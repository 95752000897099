import { fetchPage } from 'share/services/api'

export default class {
  constructor(route, relation, relationId) {
    this.route = route;
    this.relation = relation;
    this.relationId = relationId;
  }

  mapCounts(list) {
    let promises = list.map(item =>
      fetchPage(`/${item.route}/${item.id}/${this.route}`, this.route, { page: 1, per_page: 1 }).
        then(result => item[`${this.relation}Count`] = result.total));
    return Promise.all(promises).then(() => list);
  }

  mapData(list, relations) {
    list.forEach(item => item[this.relation] = relations.filter(c => c[this.relationId] === item.id));
  }
}
