import { get } from 'share/services/api'

export default ['Resty', (Resty) => Resty('documents', (klass) => {
  klass.getDetailed = (id) => Promise.all([
    klass.fetchOne(id),
    get(`/documents/${id}/files`).then(({ json }) => json.files)
  ]).then(([document, files]) => {
    document.$fileMeta = files.reduce((acc, item) => {
      acc[item.lang] = { filename: item.filename, updatedAt: item.updated_at * 1000 };
      return acc;
    }, {});
    return document;
  });
})]
