import { fetchOne, fetchListByRelationIds } from 'share/services/api'

const markDeleteable = r => {
  if(r.system) {
    r.$isDeleteable = false
  } else {
    return r.loadNestedResourcesCount("workflows").then(function() {
      // by default API creates a system workflow for each alarm type
      r.$isDeleteable = r.$workflowsCount < 2
    })
  }
}

const markDeleteableLevel = r =>
  r.loadNestedResourcesCount("workflows").
    then(() => r.$isDeleteable = r.$workflowsCount == 0);

const withLevels = r => !r.$isTechical;
const withInfos = r => !r.$isSpecific && r.$isAlarm;
const withParticipationOptions = r => r.$isAlarm;

export default ['Resty', Resty => Resty('alarm_types', klass => {
  klass.getAll = () => klass.getList({ all: true })

  klass.loadFormData = id => fetchOne(`/alarm_types/${id}`, 'alarm_types', 'alarm_type').then(alarmType => {
    var promises = [];

    if (withLevels(alarmType)) {
      promises.push(
        alarmType.loadNestedResources('alarm_levels').
          then(alarmLevels => Promise.all(alarmLevels.map(markDeleteableLevel))));
    }

    if (withInfos(alarmType)) {
      promises.push(alarmType.loadNestedResources('info_items'));
    }

    if (withParticipationOptions(alarmType)) {
      promises.push(alarmType.loadNestedResources('participation_options'));
    }

    promises.push(
      fetchListByRelationIds(`/alarm_types/${alarmType.id}/building_sectors.ids`, '/building_sectors', 'building_sectors').
        then(list => alarmType.$buildingSectors = list),
        markDeleteable(alarmType)
    );

    return Promise.all(promises).then(() => alarmType);
  });
})]
