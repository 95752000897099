import { fetchAllList, fetchOne, fetchRelationIds } from 'share/services/api'
import { mapByIds } from 'share/services/utils'

export default ['Resty', Resty => Resty('oncall_groups', klass => {
  klass.loadFormData = (id) => {
    let promises = [fetchAllList('users')];
    if(id) {
      promises.push(
        fetchOne(`/oncall_groups/${id}`, 'oncall_groups', 'oncall_group')
          .then(group => group.loadNestedResourcesCount('workflows'), Promise.reject),
        fetchRelationIds(`/oncall_groups/${id}/users.ids`)
      );
    }

    return Promise.all(promises).then(([users, group, userIds]) => {
      let dict = { users };
      if (group) {
        group.$users = mapByIds(dict.users, userIds);
      }
      return [dict, group];
    });
  }
})];
