import { fetchAllList, fetchRelationIds } from 'share/services/api'
import { mapByIds } from 'share/services/utils'

export default ['Resty', (Resty) => Resty('user_groups', klass => {
  klass.loadFormData = id => Promise.all([
    fetchAllList('users'),
    klass.fetchOne(id).then(userGroup => userGroup.loadNestedResourcesCount('workflows')),
    fetchRelationIds(`/user_groups/${id}/users.ids`)
  ]).then(([users, userGroup, userIds]) => {
    userGroup.$users = mapByIds(users, userIds);
    return [users, userGroup];
  })
})];
