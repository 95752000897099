import groupBy from 'lodash-es/groupBy'
import sortBy from 'lodash-es/sortBy'
import values from 'lodash-es/values'

export const unitTree = (units) => {
  let tree = groupBy(units, "externalUnitTypeId");
  tree = sortBy(values(tree), (units) => units[0].$externalUnitType.name);

  return tree.reduce((acc, units) => {
    acc.push({ type: units[0].$externalUnitType, units: sortBy(units, 'name') });
    return acc;
  }, []);
}
