import { Component, Fragment } from 'react'
import Alert from 'share/components/alert'
import { ModalHeader, openModal } from 'share/components/modal'
import { I18n, Translate } from 'share/services/i18n'
import { isBlank } from 'share/services/validators'
import { commit } from 'share/services/rails-fetch'

class LinkConnector extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '', error: null };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ value: e.target.value });
  }

  onSubmit() {
    let { env } = this.props;
    let config = { body: { token: this.state.value, route: `${env.modelName}s` } };
    commit(Routes.connectLinkRequest(env.id), 'PATCH', config)
      .then(() => this.props.onSuccess(),
        ({ json }) => this.setState({ error: json.error_message }));
  }

  render() {
    let { value, error } = this.state;
    return (
      <Fragment>
        <ModalHeader onClose={this.props.onCancel}>
          <Translate value="core.link_connector.title" />
        </ModalHeader>
        <div className="modal-body">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={value}
              onChange={this.onChange}
              placeholder={I18n.t('core.link_connector.placeholder')}
            />
            <div className="input-group-btn">
              <button
                type="button"
                className="btn btn-primary btn-sm-x"
                onClick={this.onSubmit}
                disabled={isBlank(value)}
              >
                <span className="fa fa-check" />
              </button>
            </div>
          </div>
          {error && <Alert mod="danger" className="mt-2">{error}</Alert>}
        </div>
      </Fragment>
    );
  }
}

export default () => ({
  scope: false,
  link($scope, $element) {
    $element.on('click', () => {
      openModal(props => <LinkConnector env={gon.env} {...props} />, { skin: 'center' }).then(() => $scope.reloadInfo(), () => null);
    });
  }
});
