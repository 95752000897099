import { I18n } from 'share/services/i18n'

export default () => ({
  require: 'ngModel',
  link($scope, $element, $attrs, ctrl) {
    let limitTo = parseInt($attrs.evaLimitTo);
    var placeholder = I18n.t('core.limit_to', { count: limitTo });
    if ($attrs.placeholder) {
      placeholder = `${$attrs.placeholder} (${placeholder})`;
    }
    $element.attr('placeholder', placeholder);

    ctrl.$parsers.push(value => {
      if(value.length > limitTo) {
        value = value.substr(0, limitTo);
        ctrl.$setViewValue(value);
        ctrl.$render();
      }
      return value;
    });
  }
});
